.register_form {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  margin-bottom: 60px;
}

.register_form > div {
  width: calc(50% - 30px);
  margin-bottom: 0 !important;
}

@media screen and (max-width: 996px) {
  .register_form {
    gap: 0;
  }

  .register_form > div {
    width: 100%;
    margin-bottom: 60px !important;
  }
  .register_form > div:nth-child(1) {
    order: 1;
    /* first name */
  }
  .register_form > div:nth-child(2) {
    order: 7;
    /* nationality */
  }
  .register_form > div:nth-child(3) {
    order: 2;
    /* last name */
  }
  .register_form > div:nth-child(4) {
    order: 8;
    /* residence */
  }
  .register_form > div:nth-child(5) {
    order: 3;
    /* email */
  }
  .register_form > div:nth-child(6) {
    order: 9;
    /* state */
  }
  .register_form > div:nth-child(7) {
    order: 4;
    /* phone */
  }
  .register_form > div:nth-child(8) {
    order: 10;
    /* city */
  }
  .register_form > div:nth-child(9) {
    order: 5;
    /* password */
  }
  .register_form > div:nth-child(10) {
    order: 11;
    /* street address */
  }
  .register_form > div:nth-child(11) {
    order: 6;
    /* repeat password */
  }
  .register_form > div:nth-child(12) {
    order: 12;
    /* apt */
  }
  .register_form > div:nth-child(13) {
    order: 14;
    /* sex */
  }
  .register_form > div:nth-child(14) {
    order: 13;
    /* zip */
  }
}

select option {
  font-family: "AvenirNextCyr-Medium" !important;
}
